
.header{
    padding-top: 40vh;
    padding-bottom:40vh;
    text-align: center;
    margin: auto;
    font-family: 'Lacquer', cursive;
}

.mainHeading{
    font-size: 130px;
    margin: 0;
}

.footer{
    margin: 0;
    text-align: center;
    font-family: 'Lacquer', cursive;
    padding-bottom: 25px;
}