.gallery{
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-width: 25%;
    -moz-column-width: 25%;
    margin: auto;
    width: 95vw;
}

.gallery .photos{
    cursor: pointer;
    margin-bottom: 10px;
}

.gallery .photos:hover{
    filter: opacity(50%)
}

